body {
  padding: 0;
  height: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Use the parent element's color */
}

a:hover {
  color: inherit; /* Ensure color doesn't change on hover */
  text-decoration: none; /* Ensure underline doesn't appear on hover */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.login-page {
  background-color: #122749;
  min-height: 100vh;
}

.login-card {
  background-color: #fff;
  max-height: 500px;
  max-width: 480px;
  position: relative;
  z-index: 99999;
}

.br-15 {
  border-radius: 15px;
}

.pool-logo {
  width: 30%;
  border-radius: 12px;
}

.formik-errors {
  color: red;
}

.login-btn {
  background-color: #2ad82a;
  border: #2ad82a;
  width: 150px;
  height: 50px;
}

.login-btn:hover {
  background-color: #122749;
  border: #122749;
  box-shadow: 0px 11px 34.9px -6px #122749;
}

.login-form::before {
  content: '';
  background-image: linear-gradient(to right, #2ad82a 0%, #29dc27a3 100%);
  width: 150px !important;
  height: 150px !important;
  border-radius: 100%;
  left: -50px !important;
  top: -50px !important;
  z-index: 2;
  position: absolute;
}
.login-form::after {
  content: '';
  background-image: linear-gradient(to left, #2ad82a 0%, #29dc27a3 100%);
  width: 150px !important;
  height: 150px !important;
  border-radius: 100%;
  right: -50px !important;
  bottom: -50px !important;
  z-index: 2;
  position: absolute;
}

.custom-input:focus {
  /* box-shadow: #37b95305 0px 1px 3px 0px, rgba(58, 140, 223, 0.15) 0px 0px 0px 1px; */
  box-shadow: #61e061 0px 2px 8px 0px;
  /* outline: #2ad82a; */
  border: 1px solid #61e061;
}

.helpline-number {
  width: unset;
  display: unset;
}

:root {
  --header-height: 55px; /* Default height, change if needed */
}

.header {
  width: 100%;
  height: var(--header-height);
  background-color: #2ad82a;
  box-shadow:
    rgba(0, 0, 0, 0.24) 0px 3px 8px,
    rgba(0, 0, 0, 0.24) 0px -3px 8px 0px;
}

.profile-img {
  height: 40px;
  border-radius: 50%;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.admin-title {
  color: #7f98b1;
}

.sidebar {
  height: calc(100vh - var(--header-height));
  /* width: 255px; */
  background-color: #122749;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.sidebar-pos-md {
  position: absolute;
}

.show-sidebar {
  display: block;
  position: absolute;
  z-index: 5;
}

.dash-pool-logo {
  margin-left: 30px;
  margin-right: 10px;
  width: 45px;
  border-radius: 10px;
}

.sidebar-toggle-btn {
  color: #fff;
  font-size: x-large;
  margin-left: 16px;
}

.side-bar-logo-div {
  height: 55px;
  background-color: #2ad82a;
  /* border-bottom: 2px solid #2ad82a; */
}

.drop-down {
  background: #2ad82a;
  border: none;
}
.drop-down:hover {
  background: #2ad82a;
  border: none;
}

.drop-down:click {
  background: #2ad82a;
  border: none;
}

.logout-img {
  width: 18px;
  margin-left: 13px;
}

.drop-down.btn.show {
  color: var(--bs-btn-active-color);
  background-color: #2ad82a;
  border-color: #2ad82a;
}

.sidebar-tab {
  color: #fff;
  height: 50px;
  padding-left: 10px;
}

.sidebar-tab:hover {
  color: #2ad82a;
}

.sidebar-icon {
  font-size: 25px;
}

.hover-green:hover {
  color: #2ad82a;
}

.sidebar-icon:hover {
  color: #2ad82a;
}

.green-bg {
  background-color: #2ad82a;
}
.green-bg:hover {
  color: #fff;
}

.scroll-auto {
  overflow-x: auto;
}

.component-frame {
  padding: 60px 60px 0 60px;
}

.content-card {
  height: 100%;
  border-radius: 12px;
  padding: 10px;
  height: 517px;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.table-head {
  --bs-table-color: #fff;
  --bs-table-bg: #254476;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.red {
  border: 1px solid #ff3232;
  color: #ff3232;
}

.yellow {
  border: 1px solid #fdc94d;
  color: #fdc94d;
}

.green {
  border: 1px solid #57f582;
  color: #57f582;
}

.blue {
  border: 1px solid #457ad1;
  color: #457ad1;
}

.icon-box {
  width: 35px;
  height: 35px;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 10px;
}

.action-icon {
  color: inherit !important;
  font-size: 21px;
}

.yes-btn {
  background-color: #2ad82a;
  border: #2ad82a;
}

.yes-btn:hover {
  background-color: #122749;
  border: #122749;
}

.w-150 {
  width: 150px !important;
}

.w-60 {
  width: 60px;
}

.w70 {
  width: 70px;
}

.pagination li {
  margin: 0 2px;
  display: flex;
}

.pagination li a {
  width: 100% !important;
  height: 100% !important;
}

.active-page {
  background-color: #1f488b;
  font-weight: 500;
}

.paginate-arrows svg {
  font-size: 30px;
  color: #1f488b;
}

.disabled-arrows svg {
  font-size: 30px;
  color: #8b8b8b;
  fill: #8b8b8b;
}

.paginate-link a {
  font-size: 40px;
}

.paginate-page {
  border: 1px solid #1f488b;
  width: 35px;
  height: 30px;
  border-radius: 8px;
  color: #1f488b;
  /* background-color: #1f488b; */
}

.paginate-link {
  color: #2ad82a;
}

.paginate-link:hover {
  color: #2ad82a;
}

.accordion-button {
  color: #fff;
}

.accordion {
  --bs-accordion-border-color: #122749;
  --bs-accordion-bg: #122749;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #122749;
  box-shadow: none;
}

/* .accordion-button {
  color: #fff;
} */

.mlm-10 {
  margin-left: -10px;
}

.accordion-body {
  padding: 0;
}

.text-editor {
  width: 100%;
  margin: 0 auto;
}
.ql-editor {
  min-height: 380px;
}

.update-btn {
  background-color: #2ad82a;
  border: #2ad82a;
  color: #fff;
  width: 150px;
  height: 40px;
  border-radius: 7px;
}

.update-btn:hover {
  background-color: #122749;
  border: #122749;
  color: #2ad82a;
  box-shadow: 0px 11px 34.9px -6px #122749;
}

.h-120 {
  height: 120px;
}

.dashboard-card {
  border: 1px solid #122749;
  background: #122749;
  color: #fff;
  border-radius: 7px;
  padding: 24px;
}

.fs-55 {
  font-size: 55px;
}

.table-responsive {
  height: 100%;
}

.driver-details-tab .nav-link {
  color: #2ad82a;
}

.driver-details-tab .nav-link.active {
  color: #417ee0;
  font-weight: 900;
}

.driver-pp {
  /* margin: auto; */
  border: 5px solid #c9c9c97a;
  width: 200px; /* Set the desired width */
  height: 200px; /* Make sure the height is the same as the width */
  border-radius: 50%;
  overflow: hidden; /* Ensure the image doesn't overflow the container */
  display: flex;
  align-items: center;
  justify-content: center;
}

.driver-pp img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container */
}

.input-details {
  border: 1px solid #c1c1c17a;
  border-radius: 8px;
}

.update-driver-btn {
  width: 45%;
  height: 42px;
  border-radius: 9px;
  color: #ffffff;
  background-color: #122749;
  border: 1px solid;
}

.wrapper {
  position: relative;
  width: 30px;
  height: 30px;
}

.wrapper input {
  opacity: 0;
  width: 30px;
}

.cloud-img {
  pointer-events: none;
  cursor: pointer;
  color: #122749;
  width: 100%;
  height: 100%;
  top: 4%;
  left: 6%;
  position: absolute;
  box-sizing: border-box;
}

.car-img {
  /* margin: auto; */
  border: 3px solid #c9c9c97a;
  width: 170px; /* Set the desired width */
  height: 180px; /* Make sure the height is the same as the width */
  border-radius: 10%;
  overflow: hidden; /* Ensure the image doesn't overflow the container */
  display: flex;
  align-items: center;
  justify-content: center;
}

.car-img img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container */
}

.label-details {
  margin-right: 10px;
  color: #9b9b9b;
}

.mr-10 {
  margin-right: 10px;
}

.custom-card {
  border-radius: 8px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 13px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.loader-div {
  background: #122749;
  opacity: 50%;
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: 9999;
}

.h-385 {
  height: 385px;
}

.tableFixHead {
  overflow: auto;
  height: 100%;
}

.tableFixHead thead th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.color-green {
  color: #2ad82a;
}
.color-red {
  color: #ff6868;
}

.h496 {
  height: 496px;
}

.header-btn {
  color: #fff;
  background: #254476;
  border: 0px;
  border-radius: 4px;
  width: 100px;
  margin-bottom: 6px;
  margin-right: 10px;
}

.add-location-btn {
  color: #fff;
  background: #254476;
  border: 0px;
  border-radius: 4px;
  width: 182px;
  height: 40px;
}

.action-btn {
  color: #fff;
  background: #254476;
  border: 0px;
  border-radius: 4px;
  width: 35px;
  height: 40px;
}

.mlm-15 {
  margin-left: -15px;
}

/* .label-class {
  position: absolute;
  top: -13px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 0px 13px;
  text-transform: uppercase;
} */

.label-class {
  position: absolute;
  top: -13px;
  background: white;
  margin-left: 8px;
  z-index: 999;
}

.label-class .error-message {
  color: red;
  margin-left: 8px; /* Optional: Adjust spacing as needed */
}

.address-td {
  max-width: 500px;
  word-wrap: break-word;
}

.location-name-td {
  max-width: 150px;
  word-wrap: break-word;
}

.helpline-btn {
  border: none;
  border-radius: 6px;
  color: #fff;
  background: #2ad82a;
  padding: 6px;
  margin-left: 10px;
}

.helpline-btn:hover {
  background: #00be00;
}

.disabled-btn {
  filter: saturate(0.1);
  cursor: unset;
}

.booking-user {
  border-radius: 8px;
  padding-top: 13px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.booking-user:hover {
  cursor: pointer;
  box-shadow: rgb(5 235 36 / 54%) 1px 1px 4px;
  /* color:rgb(5 235 36 / 54%) */
  color: rgb(33 87 137 / 81%);
}

.promotional-input-div {
  position: relative;
  margin-left: 10px;
  width: 40px;
}

.promotional-img-input {
  border: 1px blue solid;
  width: 34px;
  position: absolute;
  z-index: 1;
  opacity: 0;
}

.promotional-upload-icon {
  cursor: pointer;
  top: 0;
  left: 0;
  font-size: 32px;
  fill: #122749;
}

.promotional-banner-image {
  width: 250px;
  height: 180px;
  object-fit: fill;
}

.promotional-image-wrapper {
  display: flex;
  width: 100px;
  height: 180px;
}

.promo-submit-btn {
  border: none;
  color: #fff;
  background: #2ad82a;
  border-radius: 5px;
  width: 120px;
  height: 40px;
}

.search-input-div {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 5px;
  width: 260px;
  /* border: .5px solid red; */
}

.search-icon {
  cursor: pointer;
  position: absolute;
  right: 18px;
  font-size: 20px;
  fill: #2ad82a;
  font-weight: 900;
  /* fill: #122749; */
  /* border: 1px blue solid; */
}
.search-icon:hover {
  fill: #087c25;
}

.search-input {
  padding-left: 16px;
  padding-right: 28px;
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.search-input:focus {
  outline: none;
}

.dark-blue {
  border: 1px solid #6997e0;
  color: #6997e0;
}

.yellow {
  border: 1px solid #ffb400;
  color: #ffb400;
}

.h40 {
  height: 40px;
}

.route-update-btn {
  border: none;
  background: #2ad82a;
  color: #fff;
  border-radius: 5px;
  width: 100px;
  height: 33px;
}

.route-update-btn:hover {
  background: #122749;
}

.export-icon {
  font-size: 26px;
  margin-left: 12px;
  margin-bottom: 5px;
  cursor: pointer;
}

.dl-img {
  width: 130px;
  height: 100px;
  border-radius: 8px;
}
.export-btn {
  border: none;
  color: #fff;
  background: #2ad82a;
  border-radius: 4px;
  padding: 2px 7px;
}

.public-support {
  margin: 25px;
}
